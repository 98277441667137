a.link {
	cursor: pointer;

	&:hover {
		@apply text-tubag-red;

	}

}

%button-style {
	@apply text-sm;

	margin-left: -2px;

	border: 1.4px solid;
	border-radius: 24px;

	padding: 6px 25px;

	@apply border-black;

}

%button-hover-effect {
	&:after {
		left: 0;
		width: calc(100% + 20px);

		height: 100%;
		top: auto;
		bottom: 0;

		opacity: 1;

	}

}

.button {
	display: inline-block;

	@extend %button-style;

	@apply bg-black;
	@apply text-white;

	white-space: nowrap;

	&:hover {
		@apply bg-tubag-red;
		@apply border-tubag-red;

	}

	&.secondary {
		@apply bg-transparent;
		@apply text-black;

		&:hover {
			@apply border-tubag-red;
			@apply text-tubag-red;

		}

	}

	&.primary {
		position: relative;
		z-index: 1;
		overflow: hidden;

		border: none;
		padding: 7px 26px;

		&:after {
			content: " ";
			position: absolute;

			right: -20px;
			width: 0;

			top: 0;
			height: 0;

			z-index: -1;

			transform-origin: 0 0;
			transform: skew(-23deg);

			@apply bg-tubag-red;
			opacity: .5;

			transition: all .33s;

		}

		&:hover {
			background: #000000;

			@extend %button-hover-effect;

		}

	}

}

a:hover {
	.button.primary {
		@extend %button-hover-effect;

	}

	.button.secondary {
		@apply border-tubag-red;
		@apply text-tubag-red;

	}

}

.text-link, .text-link-highlight {
	display: inline-block;
	padding-right: 1em;

	&:after {
		content: " ";
		display: inline-block;

		width: 1em;
		margin-right: -1em;
		height: .55em;

		background: url("#{$img-root}/ui/text-link-arrow-black.svg") 100% 50%/contain no-repeat;

		position: relative;
		top: .025em;

		body.IE & {
			background-position: 85% 50%;

		}

	}

	&:hover {
		@apply text-tubag-red;

		&:after {
			background-image: url("#{$img-root}/ui/text-link-arrow-red.svg");

		}

	}

	.bg-tubag-red & {
		&:after {
			background-image: url("#{$img-root}/ui/text-link-arrow-white.svg");

		}

	}

}

.text-link {
    @apply text-sm;

    @screen md {
        @apply text-md;
    }

	@apply leading-relaxed;

	.bg-tubag-red &:hover {
		@apply text-black;

		&:after {
			background-image: url("#{$img-root}/ui/text-link-arrow-black.svg");

		}

	}

}

.text-link-highlight {
	@apply text-xl;
	@apply leading-snug;

}

a:hover {
	.text-link, .text-link-highlight {
		@apply text-tubag-red;

		&:after {
			background-image: url("#{$img-root}/ui/text-link-arrow-red.svg");

		}

	}

	.bg-tubag-red {
		.text-link, .text-link-highlight {
			@apply text-black;

			&:after {
				background-image: url("#{$img-root}/ui/text-link-arrow-black.svg");

			}

		}

	}

}

.rich-text a:hover {
	@apply text-tubag-red;
	text-decoration-color: inherit !important;
	padding-bottom: 1px;

}

.text-white {

    .button:not(span) {

        &:hover {
            @apply bg-white;
            @apply border-white;
            @apply text-black;
        }

        &.secondary {

            &:hover {
                background-color: transparent;
                color: white;
            }
        }
    }

    .text-link, .text-link-highlight {

        &:after {
            background-image: url("#{$img-root}/ui/text-link-arrow-white.svg");

        }

        &:hover {
            @apply text-black;

            &:after {
                background-image: url("#{$img-root}/ui/text-link-arrow-black.svg");

            }
        }
    }
}



.bg-blue,
.bg-green {

    &.text-white {

        .rich-text a:hover {
            color: #000000;
            text-decoration-color: #000000;
        }
    }
}
