.numbered-navigation {
	a {
		&.active,
		&:hover {
			@apply bg-tubag-red;
			
		}
		
	}
	
}
