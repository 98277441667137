/**
 * Galleries
 *
 */

.gallery {
	&__navigation {
		&__arrow {
			&--left {
				background-image: url("#{$img-root}/ui/teaser-gallery-arrow-black-left.svg");

			}

			&--right {
				background-image: url("#{$img-root}/ui/teaser-gallery-arrow-black-right.svg");

			}

		}

	}

}

.rte-gallery {
	&.gallery-primary-color {
		.gallery__navigation__arrow {
			@apply bg-tubag-red;
			
			&:hover {
				@apply bg-black;
				
			}
			
		}
		
		.gallery__navigation__progress-bar-item {
			&:after {
				@apply bg-tubag-red;
				
			}
			
		}
		
	}
	
}

/*
	Teaser galleries
*/

.teaser-gallery {
	.gallery__navigation__arrow {
		&--left {
			background-image: url("#{$img-root}/ui/teaser-gallery-arrow-black-left.svg");

		}

		&--right {
			background-image: url("#{$img-root}/ui/teaser-gallery-arrow-black-right.svg");

		}

	}

}

.tubag-product-slider img {
    margin-right: auto !important;
    
}
