@import "@sievert/Styles/_footer-brands";

footer.section {
	&:not(.alternative-background) {
		@screen xl {
			position: relative;

			&:before {
				content: " ";
				display: block;
				position: absolute;

				@apply inset-0;

				background: url("#{$img-root-akurit}/footer/background-shape-a-01.svg") 0 0/contain no-repeat;

			}

		}

		.footer-links-column {
			&:before {
				right: calc(50% - 50vw);

				background: url("#{$img-root-akurit}/footer/background-shape-a-02.svg") 100% 0/contain no-repeat;

				right: calc(50% - 50vw - 110px);

				@screen sm {
					right: calc(50% - 50vw);

				}

			}

			.row {
				@media (min-width: 560px) {
					@media (max-width: 767px) {
						.md\:w-3\/12 {
							width: 41.6666666666667%;

							&:nth-child(2) {
								margin-bottom: 20px;

							}

							&:nth-child(3) {
								width: 50%;

							}


						}

					}

				}

			}

		}

	}

	&.alternative-background {
		@screen xl {
			position: relative;

			&:before {
				content: " ";
				display: block;
				position: absolute;

				@apply inset-0;

				background: #f6f5f4 url("#{$img-root-akurit}/footer/background-shape-b-01.svg") 0 0/contain no-repeat;

			}

		}

		.footer-links-column {
			&:before {
				right: calc(50% - 50vw);

				background: #f6f5f4 url("#{$img-root-akurit}/footer/background-shape-b-02.svg") 100% 0/contain no-repeat;

				right: calc(50% - 50vw - 160px);

				@screen sm {
					right: calc(50% - 50vw - 80px);

				}
				@screen md {
					right: calc(50% - 50vw);

				}

			}

		}

	}

	.footer-legal-social {

		@media (max-width: 560px) {
			.column.w-1\/12 {
				display: none;

			}

			.column.w-11\/12 {
				width: 100%;

				.footer-legal-links {
					margin-left: -10px;
					margin-right: -10px;

					> * {
						padding-left: 10px !important;

					}

				}

			}

		}

		.footer-legal-links {
			@media (min-width: 560px) {
				@media (max-width: 767px) {
					max-width: 360px;

					margin-left: -15px;
					margin-right: -15px;

					> * {
						display: inline-block;

						margin-left: 15px !important;
						margin-right: 15px !important;

						margin-bottom: 13px !important;

					}

					span {
						width: 100% !important;
						margin-bottom: 20px !important;

					}

					margin-bottom: 30px;

				}

			}

		}

	}

	.footer-social-link {
		&:hover {
			filter: invert(13%) sepia(75%) saturate(6675%) hue-rotate(351deg) brightness(86%) contrast(112%);

		}

	}

	.footer-contact-column .footer-endorsement > div {
		> * {
			height: 23px;
			background: 50%/contain no-repeat;

		}

		.footer-endorsement {
			&-akurit {
				&, &-active {
					background-image: url("#{$img-root}/footer/endorsement-akurit-active.svg");

				}

			}

		}

    }

}

footer.campaign.section > .container .footer-contact-column .footer-endorsement {

    .footer-endorsement-brands-label {
        display: block!important;
    }
}
