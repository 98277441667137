@tailwind base;
@tailwind components;

/**
 * Available path aliases: @sievert & @akurit
 * for the asset directories in project
 */
@import "@sievert/Styles/_variables";
@import "@akurit/Styles/_variables";
@import "@tubag/Styles/_primary-color";
@import "@sievert/Styles/_base-fx";
@import "@sievert/Styles/_forms";
/*! purgecss start ignore */
@import "@tubag/Styles/_typography";
@import "@tubag/Styles/_interaction";
@import "@sievert/Styles/_ui";
@import "@tubag/Styles/_ui";
@import "@sievert/Styles/_tables";
@import "@sievert/Styles/_images";
@import "@sievert/Styles/_forms";
@import "@sievert/Styles/_grid";
// @import "@sievert/Styles/_grid-debug";
@import "@sievert/Styles/_navigation";
@import "@sievert/Styles/_navigation-brands";
@import "@akurit/Styles/_navigation";
@import "@akurit/Styles/_footer";
@import "@akurit/Styles/_background-shapes";
@import "@sievert/Styles/_campaign";
@import "@akurit/Styles/_campaign";
@import "@sievert/Styles/_accordion";
@import "@tubag/Styles/_accordion";
@import "@akurit/Styles/_story-teaser";
@import "@sievert/Styles/_gallery";
@import "@tubag/Styles/_gallery";
@import "@sievert/Styles/_downloadList";
@import "@tubag/Styles/_downloadList";
@import "@sievert/Styles/_detail-drawings";
@import "@sievert/Styles/_collage";
@import "@sievert/Styles/_video";
@import "@tubag/Styles/_sievert-logo-square";
@import "@sievert/Styles/_richtext";
@import "@tubag/Styles/_richtext-overrides";
@import "@tubag/Styles/_application-finder";
@import "@sievert/Styles/_product-detail-page";
@import "@akurit/Styles/_product-detail-page";
@import "@tubag/Styles/_product-overview-page";
@import "@sievert/Styles/_typography-campaign";
@import "@akurit/Styles/_colour-selector";
/*! purgecss end ignore */

// @import "@sievert/Styles/_grid-debug";

@tailwind utilities;
