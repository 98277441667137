/**
 * Download List
 *
 */

.download-list {
	&:hover {
		.text-link {
			@apply text-tubag-red;

		}

	}

}
