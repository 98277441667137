body.campaign-page {
	.campaign-background-shape {
		width: 52.8%;
		right: 0;

		max-width: 900px;

		&:after {
			padding-bottom: 708.8579418982935%;
			background: url("#{$img-root-akurit}/campaign/background-375.svg") 100% 0%/contain no-repeat;

		}

		@screen sm {
			width: 50%;

			&:after {
				padding-bottom: 356.4749480833878%;
				background-image: url("#{$img-root-akurit}/campaign/background-1800.svg");

			}

		}

	}

}
