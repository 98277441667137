.rich-text,
[id^=press] {
    a {
        &:hover {
            text-decoration-color: #e6000f;
			
        }
		
    }
    
}

.rich-text ul > li:before {
	top: .1em;
	
}
