/**
 * Sievert Logo Square
 * 1. in story teasers (image)
 * 2. in Headlines (text)
 */

/* 1. */

%sievert-logo-square-multiply {
	position: absolute;
	z-index: 1;

	@apply bg-tubag-red;
	mix-blend-mode: multiply;

	bottom: -20px;
	height: 40px;
	left: 25px;
	width: 40px;

	@screen md {
		bottom: -40px;
		height: 80px;
		left: 40px;
		width: 80px;

	}

	@screen lg {
		left: 0px;
		left: var(--offset-left, 0px);

	}

	@screen xl {
		bottom: -60px;
		height: 120px;
		width: 120px;

	}

}

%sievert-logo-square-multiply-reverse {
	left: auto;
	right: 25px;

	@screen md {
	   right: 40px;

	}

	@screen lg {
		right: 0px;
		right: var(--offset-right, 0px);

	}

}

.sievert-logo-square-multiply {
	--offset-left: 0px;
	--offset-right: 0px;

    &::after {
        content: " ";

        @extend %sievert-logo-square-multiply;

    }

}

.sievert-logo-square-multiply-standalone {
	> div {
		@extend %sievert-logo-square-multiply;

	}

}

.flex-row-reverse {
    .sievert-logo-square-multiply {
        &::after {
			@extend %sievert-logo-square-multiply-reverse;

        }

    }

	.sievert-logo-square-multiply-standalone {
		> div {
			@extend %sievert-logo-square-multiply-reverse;

		}

	}

}



/* 2. */

/* add default indent 1/12 only for small devices */
.sm\:indent-1 {
    margin-left: 8.333333%;

    @screen md {
        margin-left: 0;
    }
}

%sievert-logo-square-shape {
	@apply bg-tubag-red;
	height: 20px;
	left: -20px;
	position: absolute;
	top: 0;
	width: 20px;

	@screen md {
		left: -30px;
		height: 30px;
		width: 30px;
	}

	@screen lg {
		left: -40px;
		height: 40px;
		width: 40px;
	}

	@screen xl {
		left: -60px;
		height: 60px;
		width: 60px;
	}
}

.sievert-logo-square,
.sievert-logo-square-standalone {
    padding-top: 20px;

    @screen md {
        padding-top: 30px;
    }

    @screen lg {
        padding-top: 40px;
    }

    @screen xl {
        padding-top: 60px;
    }

}

.sievert-logo-square {
    &::before {
        content: " ";
		@extend %sievert-logo-square-shape;

    }
}

.sievert-logo-square-standalone {
	> div {
		@extend %sievert-logo-square-shape;
	}
	+ h1 {
		padding-top: 0;
	}
}

.story .sievert-logo-square {
    margin-top: 0px;
}

.text-white .sievert-logo-square::before {
    @apply bg-white;
}
