.background-shape-container {
	position: absolute;
	z-index: -1;

	max-height: 800px;
	width: 100%;

	main & {
		margin-top: 0;

	}

	body.transparent-header & {
		/*margin-top: 95px;

		@screen lg {
			margin-top: 100px;

		}*/

	}

	&-aligned-center {
		transform: translateY(-50%);

	}

	&-aligned-bottom {
		transform: translateY(-100%);

	}

	.background-shape {
		position: relative;

		margin-left: auto;
		margin-right: auto;

		&:after {
			content: " ";
			display: block;

			background: 50%/contain no-repeat;

		}

	}

	&-header {
		position: relative;

		height: 100vw;

		@screen sm {
			height: 67.8571428571429vw;

		}
		@screen md {
			height: 50vw;

		}

		&-a {
			margin-bottom: calc(10vw + 67px);

			@screen sm {
				margin-bottom: calc(10vw + 60px);

			}
			@screen 2xl {
				margin-bottom: calc(9% + 60px);

			}

			.background-shape {
				position: absolute;
				z-index: 16;

				background: url("#{$img-root-akurit}/background-shapes/header-a.svg") 299% -50px/contain no-repeat;
				width: 100%;
				height: 125%;

				@screen sm {
					background-position: 115% -50px;

				}
				@screen md {
					background-position: 100% -50px;

				}

				+ * {
					margin-top: 99px;

				}

				mix-blend-mode: multiply;

			}

		}

		&-b, &-c, &-d, &-e {
			margin-top: 100px;
			height: 0;

			padding-bottom: 100px;

			@screen md {
				padding-bottom: 150px;

			}
			@screen lg {
				padding-bottom: 200px;

			}
			@screen xl {
				padding-bottom: 250px;

			}

			.background-shape {
				position: absolute;
				z-index: -1;

				background: 100% 0/cover no-repeat;
				height: 400px;

				@screen md {
					height: 545px;

				}
				@screen lg {
					height: (545 + 840) * .5px;

				}
				@screen xl {
					height: 840px;

				}

				+ * {
					margin-top: 99px;

				}

			}

		}

		&-b, &-e {
			.background-shape {
				background-position: 100% 0;

			}

		}

		&-b {
			.background-shape {
				background-image: url("#{$img-root-akurit}/background-shapes/header-b.svg");

				width: 75px;

				@screen sm {
					width: calc(50% - 180px);

				}
				@screen md {
					max-width: 352px;
					width: calc(50% - 240px);

				}
				@screen lg {
					max-width: 352px;
					width: calc(50% - 240px);

				}
				@screen xl {
					max-width: 426px;
					width: calc(50% - 360px);

				}

			}

		}

		&-e {
			.background-shape {
				background-image: url("#{$img-root-akurit}/background-shapes/header-e.svg");

				width: 215px;

				@screen sm {
					max-width: 220px;
					width: calc(50% - 80px);

				}
				@screen md {
					max-width: 300px - 2px;
					width: calc(50% - 80px);

				}
				@screen lg {
					max-width: 407px;
					width: calc(50% - 240px);

				}
				@screen xl {
					max-width: 500px - 3px;
					width: calc(50% - 360px);

				}

			}

		}

		&-c, &-d {
			.background-shape {
				right: 0;
				background-position: 0 0;

			}

		}

		&-c {
			.background-shape {
				background-image: url("#{$img-root-akurit}/background-shapes/header-c.svg");

				width: 110px;

				@screen sm {
					width: calc(50% - 180px);

				}
				@screen md {
					width: calc(50% - 240px);

				}
				@screen lg {
					max-width: 432px;
					width: calc(50% - 240px);

				}
				@screen xl {
					max-width: 600px - 4px;
					width: calc(50% - 320px);

				}

			}

		}

		&-d {
			.background-shape {
				background-image: url("#{$img-root-akurit}/background-shapes/header-d.svg");

				width: 240px;

				@screen sm {
					max-width: 280px;
					width: calc(50% - 40px);

				}
				@screen md {
					max-width: 370px;
					width: calc(50% - 60px);

				}
				@screen lg {
					max-width: 500px - 10px;
					width: calc(50% - 60px);

				}
				@screen xl {
					max-width: 600px - 4px;
					width: calc(50% - 200px);

				}

			}

		}

		main > & > .background-shape {
			+ * {
				margin-top: 0;

			}

		}

		&[data-class-name] {
			.background-shape {
				background-image: none;

				overflow: hidden;

				svg {
					position: absolute;
					height: 100%;

				}

			}

			&.background-shape-container-header {
				&-a {
					svg {
						top: -50px;

						right: -34.5%;

						@screen sm {
							right: -6.5%;

						}
						@screen md {
							right: 0;

						}

					}

				}

				&-b, &-e {
					svg {
						left: 0;
						width: 100%;

					}

				}

			}

		}

	}

	[data-class-name=ImageSequencer] {
		position: absolute;
		width: 100%;
		height: 100%;

		> div {
			position: static !important;

		}

	}

	figcaption {
		display: none;

	}

	img {
		position: absolute;
		z-index: -1;

		@apply inset-0;
		height: 100%;

		object-fit: cover;
		object-position: 50% 50%;

	}

	.background-shape {
		&-akurit {
			&:after {
				background: url("#{$img-root-akurit}/background-shapes/stripes-a.svg") 50%/100% 100% repeat-x;

				padding-bottom: 600px;

				transform-origin: 0 0;
				transform: skew(-23deg);

				width: 295px;

				@screen sm {
					width: calc(50vw - 5px);

				}
				@screen md {
					width: calc(50vw - 86px);

				}
				@screen lg {
					width: calc(50vw - 206px);

				}
				@screen xl {
					width: calc(50vw - 405px);

				}

			}

			&.background-shape-shifted-left:after {

			}

			&.background-shape-shifted-right:after {

			}

			&.background-shape-aligned-right {
				&:after {
					background: url("#{$img-root-akurit}/background-shapes/stripes-b.svg") 50%/100% 100% repeat-x;

					transform-origin: 100% 100%;

					margin-left: auto;

				}

				&:after {
					width: 295px;
					min-width: 0;
					max-width: none;

					@screen sm {
						width: calc(50vw - 5px);

					}
					@screen md {
						width: calc(50vw - 86px);

					}
					@screen lg {
						width: calc(50vw - 206px);

					}
					@screen xl {
						width: calc(50vw - 405px);

					}

				}

				&.background-shape-shifted-left:after {

				}

				&.background-shape-shifted-right:after {

				}

			}

		}

	}

	&-teaser {
		&.background-shape-container-aligned-center {
			transform: translateY(calc(-100% + 60px));

			@screen lg {
				transform: translateY(-75%);

			}

		}

		.background-shape {
			&:after {
				padding-bottom: 0;
				height: 600px;
				max-height: 600px;

				@screen lg {
					height: 500px;

				}

				@screen xl {
					height: 600px;

				}

			}

		}

	}

}

.pr-background-shape-shared-content {
    margin-top: 0px !important;
    padding-bottom: 85px !important;
}


.pr-background-shape {
    position: relative;
    overflow: hidden;

    .background-shape-image > *:not(.class) {
        &:after {
            content: " ";
            position: absolute;
            z-index: 1;

            @apply inset-0;
            right: -100vw;
            @apply bg-tubag-red;

            transform-origin: 100% 100%;
            transform: skew(-23deg);

            left: 60vw;

        }

		display: none;

        + * {
            display: block;

        }

    }

    &.pr-background-shape-image {
        .background-shape-image {
            &.background-shape-image--left > *:after {
                left: -100vw;
                right: 90vw;
            }
            & > *:after {
                left: 70vw;
            }
            &.ref-background-shape > *:after {
                left: 50vw;
            }

        }

		figure {
			position: relative;
			z-index: 2;

			margin-top: 50px;
			margin-bottom: 50px;

		}

		margin-bottom: 45px;

		@screen md {
			figure {
				margin-top: 65px;
				margin-bottom: 65px;

			}

		}
		@screen xl {
			figure {
				margin-top: 90px;
				margin-bottom: 0;

			}

			margin-bottom: 90px;

			.background-shape-image > *:after {
				bottom: 125px;

			}

		}

    }

    .background-shape-image {
        &.background-shape-image--left > *:after {
            left: -100vw;
            right: 80vw;
        }
        &.background-shape-image--gray > *:after {
            @apply bg-warmgray;
        }
        &.background-shape-image--left.background-shape-image--gray > *:after {
            transform-origin: 100% 0%;
            right: 50vw;
        }
        &.background-shape-image--gray > *:after {
            left: 50vw;
        }

    }

    &.pr-background-shape-image-empty {
		overflow: visible;
		height: 0;
		z-index: -1;

		> div > div {
			position: relative;
			overflow: hidden;

			height: 100vw;

			@media (min-width: 379px) {
				height: 379px;

			}
			@screen md {
				height: 480px;

			}
			@screen lg {
				height: 560px;

			}
			@screen xl {
				height: 708px;

			}

		}

		+ .container {
			margin-top: 60px;

			@screen md {
				margin-top: 90px;

			}
			@screen xl {
				margin-top: 150px;

			}

		}

    }

}
