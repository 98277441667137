/**
 * Product Detail Page
 *
 */

.product-detail-page {
	main {
		padding-top: 86px;
		
		@screen md {
			padding-top: 49px;
			
		}
		
		.background-shape-container {
			margin-bottom: 100px;
			margin-top: 100 - 86px;
			
			@screen md {
				margin-top: 100 - 49px;
				
			}
			
		}
		
	}
	
    /* extensions to header-image */
    
	.background-shape-container {
		padding-bottom: 0;
		
		&-header {
			
			
		}
		
	}
	
}

.product-feature-icons {
	display: flex;
	
	flex: flex-wrap;
	
	margin: -15px;
	
	> * {
		margin: 15px;
		
		width: 90px !important;
		height: 160px !important;
		
	}
	
	img {
		object-fit: contain;
		
	}
	
}
