// Application Finder

.application-finder {
	
	&-form {
		position: relative;
		z-index: 1;
		
		@apply text-white;
		
		&:before {
			content: " ";
			display: block;
			position: absolute;
			z-index: -1;
			
			@apply inset-0;
			@apply bg-tubag-red;
			

			@screen md {
				transform-origin: 0% 0%;
				transform: skew(-23deg);
				
				width: calc(50vw + 625px);
				
			}
			
			@screen lg {
				width: calc(50vw + 650px);
				
			}
			
			@screen xl {
				width: calc(50vw + 550px);
				
			}
			
		}
		
		select, .select-button {
			appearance: none;
			
			background: transparent;
			border-radius: 0;
			
			border: 2px solid white;
			
			padding: .5em;
			@extend .h3;
			font-weight: 400;
			
			max-width: 760px;
			
			padding-right: 60px;
			
			overflow: hidden;
			text-overflow: ellipsis;
			
			cursor: pointer;
			
		}
		
		select {
			position: absolute;
			z-index: 1;
			opacity: 0; // .5;
			// background: green;
			
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			
			option {
				color: #000000;
				
			}
			
		}
		
		.select-button {
			position: relative;
			
			&:after {
				content: " ";
				display: block;
				
				position: absolute;
				
				right: .55em;
				top: .85em;
				
				width: 1.25em;
				height: .75em;
				
				background: url("#{$img-root}/ui/select-drop-down-large-white.svg") 100% 0%/contain no-repeat;
				
			}
		
		}
		
		select:focus + .select-button {
			outline: 2px solid white;
			outline-offset: 2px;
			
		}
		
	}
	
	&-results {
		position: relative;
		
		overflow: hidden;
		
	}
	
}
