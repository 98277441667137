.story-teaser {
	.row {
		.column:first-child > div {
			height: auto !important;
			
		}
		
	}
	
	.story-teaser-image {
		position: relative;
		
		figure {
			&:after {
				@screen sm {
					content: " ";
					
				}
				
				display: block;
				position: absolute;
				top: -1px;
				bottom: -1px;
				
				left: 100%;
				right: -30%;
				
				background: #ffffff;
				
				transform-origin: 0% 0%;
				transform: skew(-16deg);
				
			}
			
			picture {
				&, img {
					max-height: 600px;
					
					height: 66.6666666666667vw;
					
					@screen sm {
						height: 320px !important;
						
					}
					@screen md {
						height: 382px !important;
						
					}
					@screen lg {
						height: calc((25vw + 550px) * .5) !important;
						
					}
					@screen xl {
						height: calc((25vw + 600px) * .5) !important;
						
					}
					
				}
				
				img {
					object-fit: cover;
					object-position: 100% 25%;
					
				}
				
			}
			
		}
		
	}
	
	> .flex-row-reverse {
		.story-teaser-image {
			figure {
				&:after {
					left: -100%;
					right: 100%;
					
					transform-origin: 0% 100%;
					
				}
				
				picture img {
					object-position: 0 25%;
					
				}
				
			}
			
		}
		
	}
	
	.row {
		.column:last-child {
			position: relative;
			z-index: 1;
			
		}
		
	}
	
	.row[data-class-name=StoryTeaserAkurit] {
		picture {
			visibility: hidden;
			
		}
		
	}
	
}
