[data-class-name=ColourSelector] {
	position: relative;
	
	> div {
		padding-bottom: 45px;
		
	}
	
	.colour-selector-header {
		display: flex;
		justify-content: space-between;
		
		flex-direction: column;
		
		> :first-child {
			margin-bottom: 15px;
			
		}
		
		@screen sm {
			flex-direction: row;
			width: 100%;
			
			> :first-child {
				margin-bottom: 0;
				margin-right: 100px;
				
			}
			
		}
		
	}
	
	.colour-selector-progress-bar {
		display: flex;
		margin-left: -8px;
		
		@screen sm {
			margin-left: 0;
			margin-right: -8px;
			
		}
		
		&-item {
			@extend %progress-bar-item;
			
			&:after {
				background: #868686;
				
			}
			
		}
		
	}
	
	.colour-selector-main-colours-slider {
		margin-top: 22px;
		margin-bottom: 30px;
		
		@screen sm {
			margin-top: 60px;
			
		}
		
		display: flex;
		width: 100%;
		
		align-items: center;
		justify-content: space-between;
		
		@extend .unselectable;
		
		&.no-scroll {
			.colour-selector-button {
				display: none;
				
			}
			
			.colour-selector-main-colours {
				overflow: visible;
				
			}
			
			.colour-selector-colour {
				&:first-child {
					padding-left: 0;
					
				}
				
			}
			
		}
		
	}
	
	.colour-selector-colour {
		cursor: pointer;
		
	}
	
	.colour-selector-main-colours {
		flex: 0 1 auto;
		
		overflow-x: scroll;
		@extend %scroll-bar-disabled;
		
		> div {
			display: flex;
			
		}
		
		.colour-selector-colour {
			padding-left: 10px;
			padding-right: 10px;
			
			@screen lg {
				padding-left: 16px;
				padding-right: 16px;
				
				&:first-child {
					padding-left: 20px;
					
				}
				&:last-child {
					padding-right: 20px;
					
				}
				
			}
			
			.colour-selector-field {
				position: relative;
				z-index: 1;
				
				width: 60px;
				height: 60px;
				
				@screen lg {
					width: 80px;
					height: 80px;
					
				}
				
				transform-origin: 50% 0;
				transition: transform .25s;
				
			}
			
			&:hover {
				.colour-selector-field {
					transform: scale(1.045);
					
				}
				
			}
			
			&.active {
				.colour-selector-field {
					transform: scale(1.35);
					
					@screen lg {
						transform: scale(1.5);
						
					}
					
				}
				
			}
			
			.colour-selector-label {
				position: relative;
				top: .2em;
				
				font-weight: 500;
				margin-bottom: 15px;
				
				@apply text-xs;
				
				@screen lg {
					@apply text-md;
					
				}
				
				line-height: 1.5em;
				
				
			}
			
		}
		
	}
	
	.colour-selector-button {
		display: none;
		
		@screen sm {
			display: block;
			
		}
		
		flex: 0 0 auto;
		
		width: 50px;
		height: 50px;
		
		margin-bottom: 45px;
		
		@screen xl {
			width: 60px;
			height: 60px;
			
		}
		
		border-radius: 50%;
		
		background: #aaaaaa;
		
		opacity: 1;
		
		cursor: pointer;
		
		&:hover {
			@apply bg-black;
			
		}
		
		&-back {
			background-image: url("#{$img-root}/ui/gallery-arrow-neg-left.svg");
			
			margin-right: 15px;
			
			@screen lg {
				margin-right: 25px;
				
			}
			@screen xl {
				margin-right: 35px;
				
			}
			
		}
		
		&-forward {
			background-image: url("#{$img-root}/ui/gallery-arrow-neg-right.svg");
			
			margin-left: 15px;
			
			@screen lg {
				margin-left: 25px;
				
			}
			@screen xl {
				margin-left: 35px;
				
			}
			
		}
		
		opacity: .2;
		pointer-events: none;
		
		transition: opacity .25s;
		
	}
	
	.colour-selector-main-colours-slider {
		&.scrollable-left {
			.colour-selector-button-back {
				opacity: 1;
				pointer-events: auto;
				
			}
			
		}
		&.scrollable-right {
			.colour-selector-button-forward {
				opacity: 1;
				pointer-events: auto;
				
			}
			
		}
		
	}
	
	
	.colour-selector-sub-colours-list {
		@extend .unselectable;
		
		padding-top: 1px;
		padding-bottom: 1px;
		
		> div {
			display: flex;
			flex-wrap: wrap;
			
			margin: -5px -10px;
			
			@screen lg {
				margin: -5px -15px;
				
			}
			
		}
		
		.colour-selector-colour {
			margin: 5px 10px;
			
			@screen lg {
				margin: 5px 15px;
				
			}
			
			.colour-selector-field {
				width: 55px;
				height: 55px;
				
				@screen lg {
					width: 80px;
					height: 80px;
					
				}
				
				transform-origin: 50% 0;
				transition: transform .25s;
				
			}
			
			&:hover {
				.colour-selector-field {
					transform: scale(1.045);
					
				}
				
			}
			
			&.active {
				.colour-selector-field {
					transform: scale(1.375, 1.45);
					
					@screen lg {
						transform: scale(1.35);
						
					}
					
				}
				
			}
			
			.colour-selector-label {
				margin-top: .1em;
				
				font-weight: 500;
				@apply text-2xs;
				
			}
			
		}
		
	}
	
	.colour-selector-detail {
		> div {
			transform: translateZ(0);
			
			padding-top: 45px;
			padding-bottom: 15px;
			
			display: flex;
			justify-content: space-between;
			
			flex-direction: column;
			
			@screen xl {
				flex-direction: row;
				
			}
			
		}
		
		.colour-selector-detail-colour {
			display: flex;
			
			flex-direction: column;
			
			@screen lg {
				flex-direction: row;
				
			}
			
			@screen xl {
				min-width: 576px;
				
			}
			
			.colour-selector-detail-description {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				
				table {
					margin-top: 10px;
					
					@screen lg {
						margin-top: 0;
						
					}
					
					white-space: nowrap;
					
					tr {
						display: table-row;
						
					}
					
					td {
						display: table-cell;
						
						padding: 0;
						padding-top: 3px;
						
						&:first-child {
							padding-right: 30px;
							
						}
						
					}
					
				}
				
			}
			
		}
		
		.colour-selector-colour {
			margin-bottom: 15px;
			
			@screen lg {
				margin-bottom: 0;
				margin-right: 30px;
				
			}
			
			cursor: initial;
			
			.colour-selector-field {
				width: 128px;
				height: 128px;
				
				@screen sm {
					width: 180px;
					height: 180px;
					
				}
				
			}
			
			.colour-selector-label {
				display: none;
				
			}
			
		}
		
		.colour-selector-detail-links {
			margin-top: 60px;
			
			@screen xl {
				margin-top: 0;
				margin-left: 60px;
				
			}
			
			&-buttons {
				display: flex;
				flex-wrap: wrap;
				
				margin: -15px;
				margin-top: 30px;
				
				> a {
					margin: 15px;
					
				}
				
			}
			
		}
		
	}
	
	.colour-selector-overlay {
		position: relative;
		z-index: 16;
		
		width: 100%;
		
		background: #ffffff;
		
		> div {
			max-width: 760px;
			
			margin-left: auto;
			margin-right: auto;
			
		}
		
		.colour-selector-overlay-header {
			display: flex;
			width: 100%;
			
			align-items: center;
			justify-content: space-between;
			
			h3 {
				// font-weight: 500;
				
				span {
					font-weight: 400;
					white-space: nowrap;
					
				}
				
				margin-right: 1em;
				
			}
			
		}
		
		.close-button {
			@apply text-sm;
			
			white-space: nowrap;
			
			&:after {
				content: " ";
				display: inline-block;
				
				background: url("#{$img-root}/ui/icon-close-positive.svg") 100% 0/contain no-repeat;
				
				width: 1em * 1.5;
				height: 1em * 1.5;
				
				margin-left: .25em * 1.5;
				margin-bottom: -.25em * 2.25;
				
			}
			
		}
		
		.colour-selector-gallery {
			background: #ffffff;
			
			margin-top: 30px;
			padding-bottom: 45px + 36px;
			
			@screen md {
				margin-top: 45px;
				
			}
			
			.gallery {
				picture {
					background-repeat: no-repeat;
					background-position: 50%;
					background-size: cover;
					background-blend-mode: multiply;
					
				}
				
				img {
					height: 100%;
					object-fit: cover;
					
				}
				
				figcaption {
					display: none;
					
				}
				
				@screen xl {
					&__navigation {
						&__arrow {
							border-radius: 50%;
							background-color: #aaaaaa;
							opacity: 1;
							
							&:hover {
								background-color: #000000;
								
							}
							
							&--left {
								background-image: url("#{$img-root}/ui/gallery-arrow-neg-left.svg");
								
								@screen xl {
									left: -60px - 56px;
									
								}
								
							}
							
							&--right {
								background-image: url("#{$img-root}/ui/gallery-arrow-neg-right.svg");
								
								@screen xl {
									right: -60px - 56px;
									
								}
								
							}
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
